.bulk-update-date-dialog {
  width: 100%;
}

.px-dates-dropdown-label {
  display: flex;
  justify-content: space-between;
}

.display-none {
  color: transparent;
}

.calender-dialog-box .MuiPaper-root.MuiDialog-paper {
  min-width: fit-content !important;
}

.dates-drop-down {
  min-width: 350px !important;
}