// .header-settings {
//    padding-left: 50px !important;
// }
.full-width-panel {
  .ag-theme-alpine .ag-header {
    z-index: 0 !important;
  }
}
.status-container {
  display: flex;
  align-items: center;
}
.header-settings .ag-checkbox.ag-hidden {
  visibility: hidden;
  display: block !important;
}
.info-details {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.popover-title {
  display: flex;
  gap: 0.2rem;
  justify-content: flex-start;
  .MuiSvgIcon-root {
    color: #000;
  }
}
.info-items,
.history-items {
  display: grid;
  grid-template-columns: 100px 250px 250px;
  h6 {
    color: rgba(0, 0, 0, 0.54);
    margin-top: 0.05rem;
  }
}
.history-items {
  grid-template-columns: 150px 230px auto;
}
.date-passed {
  color: rgba(0, 0, 0, 0.54);
}
.date-ongoing {
  h6 {
    font-weight: 700;
  }
  color: rgba(0, 0, 0, 0.87);
  font-weight: 700;
}
.popper-root {
  width: 500px;
}
.item-status {
  margin-left: 1rem;
  color: rgba(0, 0, 0, 0.54);
}
