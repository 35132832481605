.px-create-date-filter-selector-container,
.px-create-shipping-date-filter-selector-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: space-around;
  align-items: center;
  //   justify-content: space-evenly;

  .create-dates-labels {
    font-weight: 500;
    min-width: 75px;
  }
  .gap-10 {
    gap: 10px;
  }
  .px-datefield-container {
    margin: 0px 25px;
    width: 33%;
  }
}

.px-create-dates-dialog {
  .MuiPaper-root {
    min-width: 60%;
    min-height: 400px;
    margin: 0 auto;
  }
  h4 {
    font-size: 1.25rem;
    color: RGB(var(--px_color_text_on_primary));
  }
}
