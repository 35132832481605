.px-import-review-header {
  background-color: RGB(var(--color_badge_6));
}

.px-import-review-highlight {
  background-color: bisque;
}

.px-import-review-table {
  height: 100vh;
}
.po-import-longtext {
  padding-right: 20px !important;
  word-break: break-word !important;
}

.import-review {
  .ag-cell[col-id='awardedPrice'],
  .ag-header-cell[col-id='awardedPrice'] .ag-header-cell-label {
    justify-content: flex-end;
  }
}
