.px-po-offers {
  .MuiCard-root {
    max-width: 1240px;
  }
  .px-warehouse-link {
    padding: 10px;
    color: RGB(var(--px_color_primary));
    &.active {
      border-bottom: 2px solid RGB(var(--px_color_primary));
    }
  }

  .go-to-top {
    top: -1px;
    position: sticky;
    background-color: RGB(var(--px_color_background));
    z-index: 10;
    transition: 0.2s ease-out;
  }

  .px-po-awards-tab .MuiBadge-badge {
    background-color: RGB(var(--px_color_success));
    color: RGB(var(--px_color_text_on_badge));
  }

  .px-po-reoffer-tab .MuiBadge-badge {
    background-color: RGB(var(--px_color_warning));
    color: RGB(var(--px_color_text_on_badge));
  }

  .px-po-awaiting-tab .MuiBadge-badge {
    background-color: RGB(var(--px_color_badge_3));
    color: RGB(var(--px_color_text_on_badge));
  }

  .MuiBadge-anchorOriginTopRightRectangle {
    transform: scale(1) translate(50%, -45%);
  }

  .px-po-offers-card-subtotal {
    line-height: 20px;
    padding: 0 10px;
    padding-top: 8px;
  }

  .px-po-offers-card-subtotal .totalVal {
    font-size: 18px;
    color: RGB(var(--px_color_text_primary));
  }

  .px-po-offers-card-data span {
    display: block;
    line-height: 22px;
  }

  .px-po-offers-card-data .MuiTextField-root {
    width: 90%;
    max-width: 100px;
  }
  .px-po-cards {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 10px;
  }

  .px-po-cards .px-po-card-body {
    &.hover-bg:hover {
      box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 3px -2px, rgba(0, 0, 0, 0.14) 0px 3px 4px 0px,
        rgba(0, 0, 0, 0.12) 0px 1px 8px 0px;
      border-radius: 10px;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
      border-bottom-left-radius: 10px;
      cursor: pointer;
    }
    // border-bottom: 1px solid RGB(var(--px_color_border_light));
    margin-right: 1rem;
    padding: 1rem;
  }

  .px-po-offers-card-desc {
    line-height: 26px;
    h6 {
      margin: 0;
    }
  }

  .px-po-underline {
    border-bottom: 2px solid RGB(var(--px_color_primary)) !important;
    border-radius: 0;
  }

  .px-po-warehouses .MuiButtonBase-root {
    border-bottom: 2px solid #fff;
  }

  .MuiChip-root {
    margin-top: 10px;
  }
  .MuiSvgIcon-fontSizeInherit {
    font-size: 100px !important;
  }
  .no-awards-heading-text {
    font-size: 32px;
  }
  .no-highlight-on-hover:hover {
    background-color: transparent;
  }
}
.notification-dialog {
  .MuiButton-containedPrimary {
    background-color: rgba(255, 255, 255, 0.01) !important;
    color: #2196f3;
    box-shadow: none;
    line-height: 24px;
    text-transform: uppercase;
  }
  .MuiTypography-h6 {
    font-weight: bold;
  }
  .notification-content {
    line-height: 1.5;
    letter-spacing: 0.09px;
    display: flex;
    flex-direction: column;
  }
}

.px-warning-icon {
  color: RGB(var(--px_color_warning));
}
.reofferClass {
  padding: 20px 25px 20px 25px !important;
}
