.clearing-input {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  .MuiOutlinedInput-inputMarginDense {
    min-width: 83px;
    max-width: 83px;
    padding-right:20px;
  }
  .MuiFormControl-marginNormal {
    margin: 0 !important;
    width: 111px;
  }
  .MuiSvgIcon-root {
    position: relative;
    right: 32px;
  }
}
