.px-badge-ok .MuiBadge-badge {
  background-color: RGB(var(--px_color_badge_2));
}

.px-badge-status .MuiBadge-badge {
  background-color: RGB(var(--px_color_badge_3));
}

.px-badge-warning .MuiBadge-badge {
  background-color: RGB(var(--px_color_badge_1));
}

.px-badge-warning .MuiBadge-badge,
.px-badge-status .MuiBadge-badge,
.px-badge-ok .MuiBadge-badge {
  color: RGB(var(--px_color_text_on_badge));
  margin-top: 1px;
}
.px-po-import-bar {
  button {
    margin-left: 32px !important;
  }
  .Mui-disabled {
    svg {
      fill: RGB(var(--px_color_text_muted)) !important;
    }
  }
}

.px-v-PoClearing {
  .px-min-width-buyer-table {
    min-width: 2400px;
    margin-bottom: 64px;
  }
  .ag-icon-tree-open {
    display: none !important;
  }

  .px-po-clearing-cell {
    top: 0;
  }

  .px-po-clearing-header-cell {
    padding: 10px 0 !important;
    word-break: break-word;
  }

  .ag-row-level-1 {
    background-color: #dfdfdf !important;
    .px-po-clearing-header-cell .ag-group-value {
      line-height: 40px;
    }
  }

  .ag-header-cell-label .ag-header-icon.ag-sort-order {
    display: none;
  }

  .px-po-clearing-header-cell .ag-group-value {
    padding-left: 10px;
    width: 100%;
  }

  .ag-row-level-1 .cellPadding {
    padding-left: 40px;
  }

  .px-po-grid-border-left {
    border-left: 1px solid #aaa;
  }

  .px-po-clearing-cel-a {
    position: relative;
    svg {
      position: absolute;
      top: 0;
      right: 20px;
      color: #4fa2ff;
      cursor: pointer;
      margin: 10px 0;
    }
  }

  .groupName {
    .ag-cell-wrapper {
      flex: 0 0 100%;
      .ag-cell-value {
        width: 100%;
      }
    }
  }

  .ag-theme-alpine .ag-header-cell[col-id='ag-Grid-AutoColumn'] .ag-header-cell-label {
    padding-left: 42px !important;
  }

  .ag-theme-alpine .ag-header-cell[col-id='availableToAward'] .ag-header-cell-label,
  .ag-theme-alpine .ag-header-cell[col-id='awarded'] .ag-header-cell-label,
  .ag-theme-alpine .ag-header-cell[col-id='booked'] .ag-header-cell-label {
    margin-left: -16px !important;
  }

  .ag-row-group.ag-row-level-0 {
    background-color: #eef5ff;
  }

  .ag-theme-alpine .ag-row-level-2.ag-row-hover {
    background-color: #fff !important;
  }

  .ag-theme-alpine .ag-header-viewport .ag-header-container .ag-header-row .ag-header-group-cell {
    background-color: #fff !important;
  }

  .ag-theme-alpine .ag-theme-alpine .ag-row-level-0.ag-row-even {
    background-color: #fcfcfc !important;
  }

  .ag-theme-alpine .ag-theme-alpine .ag-row-level-0.ag-row-odd {
    background-color: #ffffff !important;
  }
  //removing min height inner table
  .ag-theme-alpine .ag-layout-auto-height .ag-center-cols-clipper {
    min-height: auto !important;
  }

  .px-po-clearing-header-cell .ag-group-checkbox .ag-selection-checkbox.ag-hidden {
    visibility: hidden;
    display: block !important;
  }
  .px-fulfillment-table {
    position: relative;
  }

  .px-po-clearing-main-check {
    position: absolute;
    top: 10px;
    left: 0;
    width: 600px;
    z-index: 5;
    margin-left: 10px;
    .MuiFormControlLabel-label {
      width: 300px;
    }
  }

  .MuiCheckbox-indeterminate {
    color: #999 !important;
  }

  .px-po-clearing-last-days {
    color: RGB(var(--px_color_link));
    cursor: pointer;
    svg {
      vertical-align: middle;
    }
  }

  .ag-theme-alpine .ag-header-viewport .ag-header-container .ag-header-row .padding-left-40 {
    padding-left: 40px !important;
  }

  .ag-theme-alpine .ag-theme-alpine .ag-row-level-0.ag-row-selected,
  .ag-theme-alpine .ag-theme-alpine .ag-row-level-0.ag-row-odd.ag-row-selected {
    background-color: #f9f6d3 !important;
  }

  .px-po-grid-chart-icon {
    color: #4fa2ff;
    cursor: pointer;
  }

  .px-po-grid-padding-left {
    padding-left: 38px !important;
  }
}

.capitalize {
  text-transform: lowercase;
}
.capitalize::first-letter {
  text-transform: uppercase;
}
.demandHeader {
  padding-left: 24px !important;
}
.inventoryHeader {
  padding-left: 10px !important;
}
#demand-menu {
  z-index: 6;
  box-shadow: 0px 0px 3px #000;

  div.ag-theme-alpine {
    &.stocklist-demand-analytics-popup {
      .demand-chart {
        padding: 4px 0 0 4px;
      }

      .ag-floating-top {
        background-color: #ffffff;
      }

      .ag-floating-top-viewport {
        background-color: RGBA(var(--px_color_primary), var(--px_alpha_lighter));

        .ag-row.ag-row-even.ag-row-pinned.ag-row-level-0.ag-row-position-absolute.ag-row-first {
          background-color: RGBA(var(--px_color_primary), var(--px_alpha_lighter));
        }
      }
    }

    .ag-floating-top-container [role='row'] .ag-cell {
      display: flex;
      align-items: center;
    }

    .ag-cell {
      padding: 0 !important;
      font-size: 14px;
      line-height: 18px;
      border: none;

      &[col-id='LABEL'] {
        // width: 100px !important;

        .ag-cell-value {
          padding: 0 0 0 4px;
        }

        .ag-react-container {
          position: static;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          font-size: 10px;

          .MuiSvgIcon-root {
            position: absolute;
            height: 18px;
            width: 18px;
            top: 0;
            left: 0;
          }
        }
      }
    }

    .ag-body-horizontal-scroll {
      display: none;
    }
  }

  .MuiList-root[role='menu'] {
    padding: 0 !important;

    .ag-theme-alpine {
      padding: 8px;

      .ag-row {
        border: 0 !important;
      }
    }
  }
}
.non-clickable {
  color: #808080 !important;
  pointer-events: none;
}

.ag-theme-alpine .ag-cell[col-id='awardedPrice'] {
  .MuiOutlinedInput-adornedStart {
    padding-left: 5px;
  }
  .MuiInputAdornment-positionStart {
    margin-right: 5px;
  }
}
