.px-order-detail-management {
  --px_table_input_size_sm: 36px;
  padding-bottom: 50px;
  .px-row-key-value-col > * {
    padding: 8px 0 8px 0;
  }

  .px-order-text-field {
    margin: 20px 6px -20px 6px;
    height: var(--px_table_input_size_sm);
    // margin: 0 4px 6px 4px;
    &.MuiFormControl-root.MuiTextField-root {
      background-color: transparent;
    }
    .MuiInputBase-root {
      background-color: RGB(var(--px_color_surface));
      height: var(--px_table_input_size_sm);
      input {
        height: var(--px_table_input_size_sm);
      }
    }
    .Mui-error.MuiFormHelperText-contained {
      position: static;
      margin: 0 -4px;
    }

    .MuiFormHelperText-contained:not(.Mui-error) {
      position: static;
      margin: 0 0px;
    }
  }

  // [col-id='orderedQuantity'].ag-cell {
  //   line-height: 120px !important;
  // }

  .ag-cell {
    display: flex;
    justify-content: center;
  }

  .ag-header-cell,
  .ag-header-cell .ag-cell-label-container,
  .ag-header-cell .ag-cell-label-container .ag-header-cell-label {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .ag-ltr {
    [col-id='canceledQuantity'].ag-cell,
    [col-id='orderedQuantity'].ag-cell,
    [col-id='shippedQuantity'].ag-cell,
    [col-id='unitPrice'].ag-cell {
      border: none;
    }
  }

  [col-id='itemDescription'] .ag-cell-label-container,
  [col-id='itemDescription'],
  [col-id='itemDescription'] .ag-cell-wrapper {
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
  }

  [col-id='itemDescription'] .ag-checkbox-input {
    height: 15px;
    width: 15px;
    margin-right: 8px;
  }

  .px-min-height-34px {
    min-height: 34px;
  }

  .tooltip {
    position: relative;
    display: inline-block;
    border-bottom: 1px dotted black; /* If you want dots under the hoverable text */
  }

  /* Tooltip text */
  .px-disable-input-tooltip .tooltiptext {
    visibility: hidden;
    width: 320px;
    margin-top: 60px;
    background-color: black;
    color: #fff;
    text-align: center;
    padding: 5px 10px;
    border-radius: 6px;

    /* Position the tooltip text - see examples below! */
    position: absolute;
    z-index: 1;
  }

  /* Show the tooltip text when you mouse over the tooltip container */
  .px-disable-input-tooltip:hover .tooltiptext {
    visibility: visible;
  }

  .px-whatsapp-button {
    background-color: #23a812 !important;
    text-transform: unset;
    border-radius: 10px;
    &:hover {
      background: #23a812 !important;
    }
    .MuiButton-label {
      display: flex;
      flex-direction: column;
      color: #ffffff;
    }
  }

  .px-customer-data {
    padding: 3px 0 3px 0;
  }

  .px-row-key {
    font-weight: 500;
    font-size: 12px;
    color: RGB(var(--px_color_text_secondary));
  }

  .px-grid-chips {
    font-size: 10px;
    word-wrap: unset;
  }

  .px-margin-right-1-manage {
    margin-right: 8px;
  }

  .px-display-flex-wrap {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .px-order-detail-meta-data {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .px-billing-shipping-container {
    width: 100%;
  }

  .px-order-detail-calc-total {
    width: 90%;
  }

  .px-float-rtl {
    display: flex;
    flex-direction: row-reverse;
  }
  .px-order-action-button {
    min-width: 200px;
    margin: 4px;
  }

  .px-bottom-subtotal-text {
    line-height: 24px;
    padding: 8px 0;
    .px-bottom-subtotal-meta-data {
      font-size: 14px;
      color: RGB(var(--px_color_text_secondary));
    }
    .px-bottom-subtotal-data {
      padding-left: 8px;
      font-size: 16px;
    }
  }

  .px-right-subtotal-text {
    line-height: 18px;
    padding: 2px 0 2px 0;
    & > * {
      padding: 4px 0;
    }
    .px-right-subtotal-meta-data {
      font-size: 14px;
      color: RGB(var(--px_color_text_secondary));
    }

    .px-right-subtotal-data {
      padding-left: 8px;
      font-size: 16px;
    }
  }

  @media (min-width: 640px) {
    padding-bottom: 50px;
    .px-row-key {
      font-size: 16px;
    }

    .px-billing-shipping-container {
      width: 80%;
    }

    .px-margin-right-1-manage {
      margin-right: 16px;
    }

    .px-grid-chips {
      font-size: 14px;
    }

    .px-order-details-meta-data-container {
      display: flex;
      flex-direction: row;
    }

    .px-order-detail-meta-data {
      width: 80%;
      // &.px-order-draft-detail {
      //   width: 75%;
      // }
    }

    .px-order-detail-calc-total {
      width: 30%;
      // &.px-order-draft-detail {
      //   width: 25%;
      // }
    }
    .px-bottom-subtotal-text {
      line-height: 24px;
      padding: 8px 0;
      .px-bottom-subtotal-meta-data {
        font-size: 14px;
        color: RGB(var(--px_color_text_secondary));
      }

      .px-bottom-subtotal-data {
        padding-left: 8px;
        font-size: 16px;
      }
    }
  }
}

.PX_REQUEST_A_QUOTE {
  color: #ffffff !important;
  text-decoration: underline;
}

.px-grid-chips {
  padding: 4px 12px;
  border-radius: 16px;
  color: RGB(var(--px_color_text_on_badge));
  font-size: 14px;
}

.px-color-primary {
  color: RGB(var(--px_color_primary));
}

.px-background-primary {
  background-color: RGB(var(--px_color_primary));
}
.px-background-secondary {
  background-color: RGB(var(--px_color_secondary));
}
.px-background-error {
  background-color: RGB(var(--px_color_error));
}
.px-background-badge_1 {
  background-color: RGB(var(--px_color_badge_1));
}
.px-background-badge_1b {
  background-color: RGB(var(--px_color_badge_1b));
}
.px-background-badge_2 {
  background-color: RGB(var(--px_color_badge_2));
}
.px-background-badge_2b {
  background-color: RGB(var(--px_color_badge_2b));
}
.px-background-badge_3 {
  background-color: RGB(var(--px_color_badge_3));
}
.px-background-badge_3b {
  background-color: RGB(var(--px_color_badge_3b));
}
.px-background-badge_4 {
  background-color: RGB(var(--px_color_badge_4));
}
.px-background-badge_5 {
  background-color: RGB(var(--px_color_badge_5));
}
.px-background-badge_6 {
  background-color: RGB(var(--px_color_badge_6));
}

.px-border-bottom-1px {
  border-bottom: 1px solid #000000;
}

.px-order-edit-fees-dialog {
  .px-order-text-field-cancelicon.MuiIconButton-root {
    &:hover {
      background: none;
    }
  }
}
