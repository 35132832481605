.po-topnav,
.po-topnav-container2,
.po-topnav-container2-group1,
.po-topnav-container2-group2,
.po-topnav-mobile-container2,
.po-topnav-mobile-container2-group1,
.po-topnav-mobile-container2-group2 {
  display: flex;
}

.po-topnav {
  margin-top: 15px;
  padding: 0 15px;
  column-gap: 40px;
}

.po-topnav-container1 {
  min-width: 100px;
}

.po-topnav-menu-icon svg {
  color: RGBA(var(--px_color_text_secondary));
}

.po-topnav-container2 {
  justify-content: space-between;
  width: calc(100vw - 100px);
}

.po-topnav-container2-group1,
.po-topnav-container2-group2 {
  column-gap: 30px;
}

.po-topnav-mobile-container2,
.po-topnav-mobile-container2-group1,
.po-topnav-mobile-container2-group2 {
  flex-direction: column;
  align-items: flex-start;
  row-gap: 20px;
}

.po-topnav-menu-icon:hover {
  background-color: transparent !important;
}

.po-topnav-mobile-container2 {
  overflow: hidden;
  max-height: 0px;
  box-sizing: border-box;
  margin-left: 20px;
  transition: max-height 1s;
  #po-topnav-account {
    padding: 0;
  }
}

#po-topnav-account:hover {
  background-color: transparent;
}

.po-topnav-mobile-container2-show {
  max-height: 250px;
}

.po-topnav-links,
#po-topnav-account span {
  font-size: 17px;
  color: RGB(var(--px_color_text_secondary)) !important;
}

.po-topnav-links {
  display: flex;
  align-items: center;
}

.header-buyer {
  transition: height 0.85s;
}

@media only screen and (min-width: 875px) {
  .header-buyer {
    height: 50px;
  }
}

@media only screen and (max-width: 875px) {
  .po-topnav-container2 {
    flex-direction: column;
  }

  .po-topnav-container2-group1,
  .po-topnav-container2-group2 {
    height: 50px;
  }

  .po-topnav-container2-group2 {
    align-self: flex-end;
  }

  .header-buyer {
    height: 100px;
  }
}

@media only screen and (max-width: 750px) {
  .header-buyer {
    height: 50px;
  }
  .po-topnav {
    justify-content: space-between;
  }
}

.auto-height-mobile {
  height: 300px;
}
