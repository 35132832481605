.visibleIcon {
  cursor: pointer;
}

.ag-theme-alpine .ag-cell[col-id='fundsRemaining'],
.ag-theme-alpine .ag-cell[col-id='Total_Price'],
.ag-header-cell[col-id='fundsRemaining'] .ag-header-cell-label,
.ag-header-cell[col-id='Total_Price'] .ag-header-cell-label {
  justify-content: flex-end;
}
