.px-import-error {
  border: 1px solid RGB(var(--px_color_error));
  border-style: dashed;
  color: RGB(var(--px_color_error));
  padding: 8px;
}

.funding {
  .ag-theme-alpine .ag-body-viewport {
    scroll-behavior: smooth;
    // height: 85vh !important;
    overflow-y: auto;
  }

  .ag-body-horizontal-scroll {
    position: fixed;
    bottom: 54px;
    padding-right: 70px;
  }

  @media screen and (min-width: 1024px) {
    .ag-body-horizontal-scroll {
      position: fixed;
      bottom: 0;
      z-index: 100;
      padding-right: 70px;
    }

    .ag-body-horizontal-scroll-viewport {
      overflow-x: scroll;
      margin-right: 27px;
    }
  }

  .fundHeaderClass {
    padding-left: 20px !important;
    word-break: break-word;
  }

  .headerClass {
    padding-left: 80px !important;
  }
}

.px-funding-table {
  height: 100vh;
}

.px-min-width-funding-grid {
  height: 96vh;
  min-width: 2000px;
}

.buyerClass {
  word-break: break-word !important;
}

.px-po-import-bar {
  .MuiButton-startIcon {
    margin-top: -3px;
  }

  svg {
    fill: rgba(var(--px_color_primary), 1) !important;
  }
}

.ag-theme-alpine .ag-cell[col-id='startingBalance'],
.ag-theme-alpine .ag-cell[col-id='shippedOrderAmount'],
.ag-theme-alpine .ag-cell[col-id='NewFunds'],
.ag-theme-alpine .ag-cell[col-id='endingBalance'],
.ag-theme-alpine .ag-cell[col-id='openOrder'],
.ag-theme-alpine .ag-cell[col-id='availableFunds'],
.ag-theme-alpine .ag-cell[col-id='awardedInPeriod'],
.ag-theme-alpine .ag-cell[col-id='fulfilledInPeriod'],
.ag-theme-alpine .ag-cell[col-id='Add_Or_Subtract_Funding'],
.ag-header-cell[col-id='startingBalance'] .ag-header-cell-label,
.ag-header-cell[col-id='shippedOrderAmount'] .ag-header-cell-label,
.ag-header-cell[col-id='NewFunds'] .ag-header-cell-label,
.ag-header-cell[col-id='endingBalance'] .ag-header-cell-label,
.ag-header-cell[col-id='openOrder'] .ag-header-cell-label,
.ag-header-cell[col-id='availableFunds'] .ag-header-cell-label,
.ag-header-cell[col-id='awardedInPeriod'] .ag-header-cell-label,
.ag-header-cell[col-id='fulfilledInPeriod'] .ag-header-cell-label,
.ag-header-cell[col-id='Add_Or_Subtract_Funding'] .ag-header-cell-label {
  justify-content: flex-end;
}
