.px-search-q {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: RGB(var(--px_color_text_secondary));
}

.px-search-block {
  display: flex;
  flex-direction: row;
}

.px-search-bar {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 4px 8px;
  border: 1px solid RGBA(var(--px_color_text_secondary), var(--px_alpha_darker));
  border-radius: 4px;

  .MuiTextField-root .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
    border: none;
  }

  .MuiSelect-outlined.MuiSelect-outlined {
    border-right: 1px solid RGBA(var(--px_color_text_secondary), var(--px_alpha_darker));
    color: RGBA(var(--px_color_text_secondary), var(--px_alpha_darker));
    border-radius: 0px;
  }

  .px-search-option.MuiFormControl-root.MuiTextField-root {
    min-width: 100px;
  }

  .px-search-text {
    min-width: 360px;
  }
}
