.px-advance-chip-filters {
  // .MuiChip-label {
  //   // max-width: 200px;
  // }
  .px-chips-filter-toggle {
    text-transform: uppercase;
    box-sizing: border-box;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
  button:first-child,
  button div {
    margin-left: 0 !important;
    padding-left: 0;
  }
}

.px-new-filter-radio {
  justify-content: flex-start;
  align-items: flex-start !important;
  .MuiIconButton-label {
    padding: 0;
    margin: 0 8px;
  }
}

.px-helper-text-margin-top-1 p.MuiFormHelperText-root.Mui-error {
  position: absolute;
  line-height: 12px;
  bottom: -2.6em;
}

.px-chips-filter-dialog {
  .MuiPaper-root {
    width: 40%;
    min-height: 200px;
    margin: 0 auto;
  }
  h4 {
    font-size: 1.25rem;
    color: RGB(var(--px_color_text_on_primary));
  }
}

.px-chips-filter-title {
  font-size: 1.45rem !important;
  font-weight: bolder;
}

.px-chips-filter-dialog-actions {
  button {
    padding-left: 20px;
    padding-right: 20px;
    margin: 10px 20px;
  }
}

.px-chips-filter-list {
  max-height: 300px;
  overflow-y: auto;
  overflow-x: hidden;
  div {
    display: block;
    margin: 0 0 20px 0;
    width: 100%;
  }
}
