.px-time-select-filter-selector-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: space-around;

  .offer-times-labels {
    display: flex;
    align-items: center;
    font-weight: 500;
    min-width: 75px;
  }

  .px-timefield-container {
    margin: 0px 35px;
    width: 30%;
    min-width: 100px;
  }
}

.px-offer-times-dialog {
  .MuiPaper-root {
    min-width: 30%;
    min-height: 340px;
    margin: 0 auto;
  }
  h4 {
    font-size: 1.25rem;
    color: RGB(var(--px_color_text_on_primary));
  }
}
