.px-contracts {
  .link {
    color: RGB(var(--px_color_link));
    cursor: pointer;
  }
  h3 {
    font-size: 1.5rem;
  }
  h5 {
    line-height: 40px;
  }
  .checked {
    vertical-align: middle;
    color: #4bb150 !important;
    margin-right: 10px;
  }
  .unchecked {
    color: #ccc !important;
    vertical-align: middle;
    margin-right: 10px;
  }
  .card {
    max-width: 600px;
    background-color: RGB(var(--px_color_border_light));
    padding: 20px;
  }
  .px-contracts-policy.link {
    span {
      display: inline-block;
      line-height: 55px;
      vertical-align: middle;
    }
    span:first-child {
      width: 245px;
      font-size: 20px;
    }
    span:nth-child(2) {
      padding-top: 10px;
    }
    span:last-child {
      font-size: 13px;
      color: RGBA(var(--px_color_text), var(--px_alpha_darker));
      padding-left: 10px;
    }
  }
}

.px-contracts-popup {
  h2 {
    font-size: 24px !important;
  }
  a {
    cursor: default;
    color: #000 !important;
    text-decoration: none;
  }
}
