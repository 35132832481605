.px-timefield-container {
  display: flex;
  flex-direction: column;
  .MuiFormHelperText-root {
    position: absolute;
    bottom: -16px;
  }
}

.px-time-dialog .MuiPickersBasePicker-container .MuiButton-root {
  color: var(--px_color_text_on_primary);
}
