.px-billing-shipping-flex-start {
  justify-content: flex-start;
  align-items: flex-start;
}

.px-additional-serviced-meta-data {
  //   position: absolute;
  //   margin-top: 18px !important;
  //   max-width: 90%;
  padding: 4px 0 16px 0;
}

.px-billing-shipping-flex-start {
  justify-content: flex-start;
  align-items: flex-start !important;
}

.px-additional-services-options {
  .MuiButtonBase-root {
    padding: 0 8px 0 8px;
  }
  label {
    padding: 0 0 12px 0;
  }
}

.px-checkout-data {
  // position: relative;
  padding: 30px 0;
  // .MuiSwitch-root {
  //   position: absolute;
  //   top: 60px;
  //   right: 0;
  // }
  .MuiFormGroup-root {
    .MuiFormControl-marginNormal {
      margin-top: 0;
      margin-bottom: 16px;
    }
  }

  .margin-formControl {
    margin-bottom: 6px;
  }
}
.px-checkout-data.px-box {
  padding: 20px;
  margin-bottom: 20px;
  box-shadow: none;
}
.px-checkout-calc {
  //max-width: 800px;
  padding-bottom: 50px;
  .px-button-maxwidth {
    max-width: 259px;
  }
  .px-cart-submission-btns {
    flex-wrap: nowrap;
  }
}
.px-checkout-summary {
  max-width: 240px;
  margin: 60px auto;
  font-size: 1.2rem;
  .strong {
    font-weight: 600;
  }
}
.px-checkout-bar.border-left.show-for-sm-only {
  border-left: 0;
  margin-top: 30px;
  .px-checkout-summary {
    margin: 60px auto 20px;
  }
}

@media screen and (min-width: 640px) {
  .px-checkout-data.px-box {
    padding: initial;
    margin-bottom: initial;
    border-radius: 0;
    border: none;
    border-bottom: 1px solid RGB(var(--px_color_text_primary));
    padding: 30px 0 15px 0;
    -webkit-box-shadow: 0;
    -moz-box-shadow: 0;
    box-shadow: 0;
  }
  .px-checkout-calc {
    padding-bottom: 150px;
  }
  .px-checkout-bar {
    padding-left: 20px;
  }
}

.px-billing-shipping-flex-start {
  justify-content: flex-start;
  align-items: flex-start;
}
.px-order-container {
  display: flex;
  align-items: flex-start;
  margin: 10px;
}
.order-header {
  font-weight: bold;
}
.px-address-container {
  display: flex;
  flex-direction: row;
  margin-bottom: 50px;
  margin-left: 10px;
}
.px-flexbox-item {
  width: 350px;
  margin-right: 20px;
}
