.ag-theme-alpine {
    .ag-header-group-cell {
      background-color: #f8f8f8 !important;
    }
    .ag-header-group-text {
      padding-left: 8px !important;
      font-weight: 700 !important;
      color: black !important;
    }
  }
