.px-v-dashboard {
  position: relative;
  // min-height: 100vh;
}

a.px-error-logout {
  color: #fff !important;
  text-decoration: underline;
}

.px-import-popup {
  z-index: 9999 !important;
  .MuiDialogActions-root {
    padding: 10px 20px 20px 0 !important;
    button:first-of-type {
      margin-right: 20px !important;
    }
  }
  #alert-dialog-description {
    font-size: 1rem;
  }
}

.MuiFormControl-root.px-popup-menu {
  min-width: 200px;
}

.pointer-mouse {
  cursor: pointer;
}

.MuiDropzoneArea-text {
  margin: 24px !important;
}

amplify-authenticator {
  --container-align: top !important;
}

// .ag-theme-alpine .ag-header-cell,
// .ag-theme-alpine .ag-header-group-cell {
//   padding-left: 10px;
//   padding-right: 5px;
// }

.ag-header-cell-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: break-spaces;
}
.awaitingReview.MuiChip-root {
  border-color: RGB(var(--px_color_badge_3));
  &.MuiChip-outlined,
  &.MuiChip-outlined:hover,
  &.MuiChip-outlined:focus {
    color: RGB(var(--px_color_badge_3));
    background-color: RGBA(var(--px_color_badge_3), var(--px_alpha_light));
  }
  &.px-filter-on,
  &.px-filter-on:hover,
  &.px-filter-on:focus {
    background-color: RGB(var(--px_color_badge_3)) !important;
    color: RGB(var(--px_color_text_on_badge)) !important;
  }
  &.px-filter-off,
  &.px-filter-off:hover,
  &.px-filter-off:focus {
    background-color: RGB(var(--px_color_text_muted)) !important;
    color: RGB(var(--px_color_text_on_badge)) !important;
  }
}

.awarded.MuiChip-root {
  border-color: RGB(var(--px_color_badge_2));
  &.MuiChip-outlined,
  &.MuiChip-outlined:hover,
  &.MuiChip-outlined:focus {
    color: RGB(var(--px_color_badge_2));
    background-color: RGBA(var(--px_color_badge_2), var(--px_alpha_light));
  }
  &.px-filter-on,
  &.px-filter-on:hover,
  &.px-filter-on:focus {
    background-color: RGB(var(--px_color_badge_2)) !important;
    color: RGB(var(--px_color_text_on_badge)) !important;
  }
}

.reOffer.MuiChip-root {
  border-color: RGB(var(--px_color_badge_1));
  &.MuiChip-outlined,
  &.MuiChip-outlined:hover,
  &.MuiChip-outlined:focus {
    color: RGB(var(--px_color_badge_1));
    background-color: RGBA(var(--px_color_badge_1), var(--px_alpha_light));
  }
  &.px-filter-on,
  &.px-filter-on:hover,
  &.px-filter-on:focus {
    background-color: RGB(var(--px_color_badge_1)) !important;
    color: RGB(var(--px_color_text_on_badge)) !important;
  }
  &.px-filter-off,
  &.px-filter-off:hover,
  &.px-filter-off:focus {
    background-color: #bbbbbb !important;
    color: RGB(var(--px_color_text_on_badge)) !important;
  }
}

.rejected.MuiChip-root {
  border-color: RGB(var(--px_color_badge_6));
  &.MuiChip-outlined,
  &.MuiChip-outlined:hover,
  &.MuiChip-outlined:focus {
    color: RGB(var(--px_color_badge_6));
    background-color: RGBA(var(--px_color_badge_6), var(--px_alpha_light));
  }
  &.px-filter-on,
  &.px-filter-on:hover,
  &.px-filter-on:focus {
    background-color: RGB(var(--px_color_badge_6)) !important;
    color: RGB(var(--px_color_text_on_badge)) !important;
  }
}
// .ag-theme-alpine .ag-group-expanded .ag-icon {
//   color: RGB(var(--px_color_primary));
// }
