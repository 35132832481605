.px-sideMenu-sticky-container {
  position: relative;
  z-index: 1000;
  border-right: 1px solid RGB(var(--px_color_border));
  background-color: RGB(var(--px_color_surface));
  // overflow: visible;
  // height: 0;
  top: 0;
  left: 0;
  bottom: 0;
  // width: 0;
}

.px-c-SideMenu {
  display: none;
  white-space: nowrap;
  border-right: 1px solid RGB(var(--px_color_border));
  background-color: RGB(var(--px_color_surface));
  // height: 100vh;
  position: absolute;
  height: 100%;
  min-width: 30px;
  padding-bottom: 0;
  border-top: 0;

  .MuiList-root.px-position-sticky-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
  }

  .px-menu-desk.px-position-sticky-top {
    position: -webkit-sticky;
    position: sticky;
    top: 49px;
  }

  .px-sideMenu-heading {
    h5 {
      font-weight: 600;
    }

    &:hover {
      background: none;
    }
  }

  .px-expand-side-menu .MuiIconButton-root {
    &:hover {
      background: none;
    }
  }

  .px-expand-menu-icon {
    color: RGB(var(--px_color_text_secondary));
  }

  .px-sideMenu-item {
    padding: 8px 16px 8px 16px;
    width: 200px;
    cursor: pointer;
    text-align: left;
    height: 48px;
    color: RGB(var(--px_color_text_primary));

    .px-sideMenu-item-icon {
      margin-right: 20px;
      color: RGB(var(--px_color_text_secondary));
    }
  }

  .px-sideMenu-section-break {
    font-size: 0.9rem;
    color: RGB(var(--px_color_text_on_secondary));
    padding-left: 5px;
    margin-bottom: 0px;
  }

  .px-active-navlink {
    background-color: RGBA(var(--px_color_primary), var(--px_alpha_light));
    color: RGBA(var(--px_color_primary));

    .px-sideMenu-item-icon {
      color: RGBA(var(--px_color_primary));
    }
  }

  .px-sideMenu-opened {
    background-color: RGBA(var(--px_color_primary), var(--px_alpha_lighter));
  }
}

.px-menu-with-sub {
  padding-right: 0;
}

.px-menu-mobile {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 1100;
  margin: 0;
  list-style: none;
  color: RGB(var(--px_color_text_primary));
  background-color: RGB(var(--px_color_background));

  li {
    margin: 0;
    padding: 0;
    width: 28%;
    height: 54px;
    padding-top: 7px;
    text-align: center;
    display: inline-block;
    vertical-align: middle;
    line-height: 12px;
  }

  li:first-of-type {
    width: 16%;
    padding-top: 17px;
  }
}

.px-c-SideMenu.show-menu-small-device {
  display: block;

  .px-expand-side-menu {
    display: none;
  }
}

@media only screen and (min-width: 1024px) {
  .px-sideMenu-sticky-container {
    width: 36px;
    z-index: 1299;
  }

  .px-c-SideMenu {
    display: initial;

    &.px-menu-autoclose {
      width: 36px;

      .px-menu-desk {
        display: none !important;
      }

      .px-expand-side-menu {
        display: block;
      }

      &.show-menu {
        position: absolute;
        height: 100%;
        width: auto;

        .px-menu-desk {
          display: block !important;
        }

        .px-expand-side-menu {
          display: none;
        }
      }
    }
  }

  .px-c-SideMenu.px-menu-autoclose-off {
    position: initial;
    padding-top: 0;
    top: 0;
  }
}